var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "请选择代理商" },
              model: {
                value: _vm.operatorId,
                callback: function ($$v) {
                  _vm.operatorId = $$v
                },
                expression: "operatorId",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.operatorNm, value: item.operatorId },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "280px", "margin-left": "20px" },
              attrs: { placeholder: "搜索用户名/手机号" },
              model: {
                value: _vm.userOrNum,
                callback: function ($$v) {
                  _vm.userOrNum = $$v
                },
                expression: "userOrNum",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "border-radius": "0 4px 4px 0",
                    "margin-right": "-5px",
                  },
                  attrs: {
                    slot: "suffix",
                    type: "primary",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchData },
                  slot: "suffix",
                },
                [_vm._v("搜索")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  width: "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: "用户昵称",
                  align: "center",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "realName",
                  label: "代理商",
                  align: "center",
                  "min-width": "80",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  label: "问题类型",
                  align: "center",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "state",
                  align: "center",
                  label: "处理状态 ",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "rmk",
                  label: "问题描述 ",
                  align: "center",
                  "min-width": "80",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mobile",
                  label: "用户手机号 ",
                  align: "center",
                  "min-width": "110",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "handler",
                  label: "处理人",
                  align: "center",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "handleTime",
                  label: "处理时间",
                  align: "center",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "deviceNo",
                  label: "设备编号 ",
                  align: "center",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  align: "center",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "comment",
                  label: "批注 ",
                  align: "center",
                  "min-width": "80",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "editCell" },
                          [
                            scope.row.state == "待处理"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.detailHandler(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("处理")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            attrs: {
              background: false,
              page: _vm.page,
              limit: _vm.pageSize,
              total: _vm.total,
            },
            on: {
              pagination: _vm.handleCurrentChange,
              "update:page": function ($event) {
                _vm.page = $event
              },
              "update:limit": function ($event) {
                _vm.pageSize = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看详情",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "order" }, [
            _c("div", { staticClass: "order-1" }, [
              _c("div", { staticClass: "text" }, [_vm._v("用户昵称")]),
              _vm._v("\n        " + _vm._s(_vm.data.userName) + "\n      "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-1" }, [
              _c("div", { staticClass: "text" }, [_vm._v("问题描述")]),
              _vm._v("\n        " + _vm._s(_vm.data.rmk) + "\n      "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-1" }, [
              _c("div", { staticClass: "text" }, [_vm._v("手机号")]),
              _vm._v("\n        " + _vm._s(_vm.data.mobile) + "\n      "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-1" }, [
              _c("div", { staticClass: "text" }, [_vm._v("问题类型")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.data.type ? _vm.data.type : "其他问题") +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-1" }, [
              _c("div", { staticClass: "text" }, [_vm._v("创建时间")]),
              _vm._v("\n        " + _vm._s(_vm.data.createTime) + "\n      "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-1" },
              [
                _c("div", { staticClass: "text" }, [_vm._v("批注")]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { placeholder: "请输入批注信息" },
                  model: {
                    value: _vm.comment,
                    callback: function ($$v) {
                      _vm.comment = $$v
                    },
                    expression: "comment",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.doFeedBack("2")
                    },
                  },
                },
                [_vm._v("忽略")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.doFeedBack("1")
                    },
                  },
                },
                [_vm._v("处理")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }