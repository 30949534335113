<template>
	<div class="journal-body">
		<div class="balance-body">
			<div class="data-container">
				<div class="middle-data">
					<el-row :gutter="12">
						<el-col :span="16">
							<div class="left-content">
								<div style="display: flex;justify-content: space-between;align-items: center;">
									<div class="left-title">流水分类统计</div>
									<div class="left-time">
										<el-date-picker v-model="searchTimeData" type="daterange"
											:picker-options="pickerOptions" start-placeholder="开始日期"
											end-placeholder="结束日期" align="right" :clearable="false"
											style="width: 320px;" @change="handleTimeData">
											<template slot="range-separator" scope="{ start, end }">
												<img src="@/assets/SwapRight.svg" alt="">
											</template>
										</el-date-picker>
									</div>
								</div>
								<div class="data-datail">
									<el-row>
										<el-col :span="6">
											<div class="item-data">
												<div class="left-icon">
													<img src="@/assets/journal/data5.png" class="img-content" />
												</div>
												<div>
													<div class="item-title">账户充值金额 (元)</div>
													<div class="item-val">{{ totalData.accountAmount }}</div>
												</div>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="item-data">
												<div class="left-icon">
													<img src="@/assets/journal/data1.png" class="img-content" />
												</div>
												<div>
													<div class="item-title">充电消费金额 (元)</div>
													<div class="item-val">{{ totalData.chargeAmount }}</div>
												</div>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="item-data">
												<div class="left-icon">
													<img src="@/assets/journal/data3.png" class="img-content" />
												</div>
												<div>
													<div class="item-title">订单退款金额 (元)</div>
													<div class="item-val">{{ totalData.refundAmount }}</div>
												</div>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="item-data">
												<div class="left-icon">
													<img src="@/assets/journal/data2.png" class="img-content" />
												</div>
												<div>
													<div class="item-title">账户提现金额 (元)</div>
													<div class="item-val">{{ totalData.withdrawAmount }}</div>
												</div>
											</div>
										</el-col>
									</el-row>
								</div>

							</div>
						</el-col>
						<el-col :span="8">
							<div class="right-content">
								<div style="display: flex;justify-content: space-between;align-items: center;">
									<div class="right-title">平台账户统计</div>
									<div style="height: 32px;width: 20px;"></div>
								</div>
								<div class="data-datail">
									<el-row>
										<el-col :span="12">
											<div class="item-data">
												<div class="left-icon">
													<img src="@/assets/journal/data4.png" class="img-content" />
												</div>
												<div>
													<div class="item-title" style="display: flex;align-items: center;">
														月初账户总余额 (元)
														<!-- <el-tooltip class="item" effect="dark"
															content="此数据仅根据访问时间及查询区的车主手机变动" placement="top"><img
																src="@/assets/detsri.png"
																style="margin-left:4px;vertical-align: middle; width: 14px;height: 14px;"
																alt=""></el-tooltip> -->
													</div>
													<div class="item-val">{{ totalData.beginBalance }}</div>
												</div>
											</div>
										</el-col>
										<el-col :span="12">
											<div class="item-data">
												<div class="left-icon">
													<img src="@/assets/journal/data6.png" class="img-content" />
												</div>
												<div>
													<div class="item-title" style="display: flex;align-items: center;">
														最新账户总余额 (元)
														<!-- <el-tooltip class="item" effect="dark"
															content="此数据仅根据访问时间及查询区的车主手机变动" placement="top"><img
																src="@/assets/detsri.png"
																style="margin-left:4px;vertical-align: middle; width: 14px;height: 14px;"
																alt=""></el-tooltip> -->
													</div>
													<div class="item-val">{{ totalData.latestBalance }}</div>
												</div>
											</div>
										</el-col>
									</el-row>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>


			<div class="search-content">
				<el-form ref="searchFormRef" :model="searchForm" label-suffix=":" label-width="14px">
					<div style="display: flex;">
						<el-row :gutter="8" type="flex" style="flex:1;flex-wrap:wrap;">
							<el-col :span="8">
								<el-form-item prop="mobile">
									<div style="display: flex;">
										<span style="margin-right: 8px; white-space: nowrap;">车主手机</span>
										<el-input maxlength="11" v-model.trim="searchForm.mobile" placeholder="请输入"
											clearable></el-input>
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item prop="classify">
									<div style="display: flex;">
										<span style="margin-right: 8px; white-space: nowrap;">交易类型</span>
										<el-select v-model="searchForm.classify" filterable placeholder="请选择"
											style="width: 100%;" clearable>
											<el-option v-for="(item, index) in marketList" :key="index"
												:label="item.type" :value="item.classify">
											</el-option>
										</el-select>
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item ref="timeRange" prop="timeRange">
									<div style="display: flex;">
										<span style="margin-right: 8px; white-space: nowrap;">交易时间</span>
										<!-- <el-date-picker
                                            v-model="searchForm.startTime"
                                            type="date"
                                            placeholder="开始日期"
                                            align="right"
                                            :picker-options="pickerStartOptions">
                                        </el-date-picker>
                                        <div style="margin: 0 6px;"><img class="date-time" style="width: 14px;height: 14px;" src="@/assets/swapright.png" alt=""></div>
                                        <el-date-picker
                                            v-model="searchForm.endTime"
                                            type="date"
                                            placeholder="结束日期"
                                            align="right"
                                            :picker-options="pickerEndOptions">
                                        </el-date-picker> -->
										<el-date-picker v-model="searchForm.timeRange" type="daterange"
											:picker-options="pickerOptions" start-placeholder="开始日期"
											end-placeholder="结束日期" align="right" :clearable="false">
											<template slot="range-separator" scope="{ start, end }">
												<img src="@/assets/SwapRight.svg" alt="">
											</template>
										</el-date-picker>
									</div>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item prop="stat">
									<div style="display: flex;">
										<span style="margin-right: 8px; white-space: nowrap;">交易状态</span>
										<el-select v-model="searchForm.stat" filterable placeholder="请选择"
											style="width: 100%;" clearable>
											<el-option v-for="(item, index) in statusList" :key="index"
												:label="item.type" :value="item.stat">
											</el-option>
										</el-select>
									</div>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<div style="margin:0 12px; width: 1px;height: 80px;background-color: #E5E6EB;">
							</div>
						</el-row>
						<el-row>
							<div style="display: flex;">
								<el-button @click="searchData" type="primary">查询</el-button>
								<el-button @click="resetData" plain style="margin-left:8px;">重置</el-button>
							</div>
						</el-row>
					</div>
				</el-form>
			</div>
			<!-- 列表 -->
			<div class="table-body">
				<div>
					<el-button @click="handleExportFile" type="primary">导出</el-button>
				</div>
				<div class="table-list">
					<el-table v-loading="tableLoading" :data="tableData"
						:header-cell-style="{ background: '#F7F8FA', color: '#1D2129  !important' }"
						style="width: 100%">
						<el-table-column type="index" label="序号" width="60"></el-table-column>
						<el-table-column prop="tradeNo" label="流水号" min-width="100" show-overflow-tooltip>
							<template slot-scope="{row}">
								<span v-if="row.tradeNo && row.tradeNo != ''">{{ row.tradeNo }}</span>
								<span v-else>-</span>
							</template>
						</el-table-column>
						<el-table-column prop="tradeTime" label="交易时间" min-width="120"
							show-overflow-tooltip></el-table-column>
						<el-table-column prop="mobile" label="车主手机" min-width="100">
							<template slot-scope="{row}">
								<span style="color: #057C98;cursor: pointer;" @click="handleisShowDetailDrawer(row)">{{
									row.mobile }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="classify" label="交易类型" min-width="90">
							<template slot-scope="{row}">
								<span v-if="row.classify == 1">账户充值</span>
								<span v-if="row.classify == 3">充电消费</span>
								<span v-if="row.classify == 7">平台赠送</span>
								<span v-if="row.classify == 71">充值奖励</span>
								<span v-if="row.classify == 80" style="display: inline-flex;align-items: center;">订单退款
									<el-tooltip class="item" effect="dark" :content="row.remarks" placement="top">
										<img src="@/assets/detsri.png"
											style="margin-left: 4px; width: 12px;height: 12px;cursor: pointer;" alt="">
									</el-tooltip>
								</span>
								<span v-if="row.classify == 90" style="display: inline-flex;align-items: center;">账户提现
									<el-tooltip v-if="row.remarks" class="item" effect="dark" :content="row.remarks"
										placement="top">
										<img src="@/assets/detsri.png"
											style="margin-left: 4px; width: 12px;height: 12px;cursor: pointer;" alt="">
									</el-tooltip>
								</span>
							</template>
						</el-table-column>
						<el-table-column prop="amount" label="交易金额 (元)" min-width="90">
							<template slot-scope="{row}">
								<span :style="{ color: row.amount < 0 ? '#DF3737' : '#08AB67' }">{{ row.amount }}</span>
								<!-- <span v-if="row.classify == 80">
									<el-tooltip class="item" effect="dark" :content="row.remarks" placement="top">
										<img src="@/assets/detsri.png"
											style="margin-left: 4px; width: 12px;height: 12px;cursor: pointer;" alt="">
									</el-tooltip>
								</span> -->
							</template>
						</el-table-column>
						<el-table-column prop="state" label="交易状态" min-width="100">
							<template slot-scope="{row}">
								<div style="display: flex;font-size: 12px;">
									<div v-if="row.state == 0" class="table-state" @click="handleShowRightData(row)">
										失败 <img src="@/assets/bal-filer.png" alt="">
									</div>
									<div v-if="row.state == 1" class="table-state" style="color: #F7A70D;"
										@click="handleShowRightData(row)">
										提现中 <img src="@/assets/bal-run.png" alt="">
									</div>
									<div v-if="row.state == 2" class="table-state" style="color: #08AB67;"
										@click="handleShowRightData(row)">
										部分成功 <img src="@/assets/bal-success.png" alt="">
									</div>
									<div v-if="row.state == 3" class="table-state" @click="handleShowRightData(row)"
										style="color: #08AB67;">
										成功 <img v-if="row.classify == 90" src="@/assets/bal-success.png" alt=""></div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="beforeAmount" label="交易前金额 (元)" min-width="100">
							<template slot-scope="{row}">
								<span v-if="row.beforeAmount && row.beforeAmount != ''">{{ row.beforeAmount }}</span>
								<span v-else>-</span>
							</template>
						</el-table-column>
						<el-table-column prop="afterAmount" label="交易后金额 (元)" min-width="100">
							<template slot-scope="{row}">
								<span v-if="row.afterAmount && row.afterAmount != ''">{{ row.afterAmount }}</span>
								<span v-else>-</span>
							</template>
						</el-table-column>
						<el-table-column prop="orderNo" label="关联订单" min-width="120" show-overflow-tooltip>
							<template slot-scope="{row}">
								<span v-if="row.orderNo && row.orderNo != ''" style="cursor: pointer;color: #057C98;">
									<router-link :to="`/order/orderDetail/${row.orderNo}`">{{ row.orderNo
										}}</router-link>
								</span>
								<span v-else>-</span>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="paginationObj.page" :page-size="paginationObj.listPageSize"
						layout="total, prev, pager, next,sizes, jumper" :total="paginationObj.total"></el-pagination>
				</div>
			</div>
			<!-- 提现原因 -->
			<el-drawer title="账户提现明细" :visible.sync="isShowDrawer" direction="rtl" :modal="false" size="448px">
				<div class="drawer-phone">
					<div> <img src="@/assets/dreaw-icon.png" alt=""> 车主手机号: {{ rightDrawerObj.mobile }}</div>
				</div>
				<div>
					<div class="border-style">
						<div class="radiu-body"></div>
					</div>
					<div class="drawer-detail">
						<div class="tips-body" v-show="rightDrawerObj.rightDrawerState == 3">
							<div class="detail-tips">
								<ul>
									<li>
										提现根据充值单逐笔退回，可能有多条退款单，可根据提现成功时间在【微信-服务-钱包-账单】中查看明细
									</li>
									<!-- <li>
                                        部分提现失败：微信返回的失败原因
                                    </li> -->
								</ul>
							</div>
						</div>
						<div class="tips-body2" v-show="rightDrawerObj.rightDrawerState == 0">
							提现失败：微信提现失败
						</div>
						<div class="tips-body" v-show="rightDrawerObj.rightDrawerState == 1">
							<div class="detail-tips">
								提现操作有一定的延迟，常规1-7个工作日到账
							</div>
						</div>
						<div class="tips-body" v-show="rightDrawerObj.rightDrawerState == 2">
							<div class="detail-tips">
								<ul>
									<li>
										提现根据充值单逐笔退回，可能有多条退款单，可根据提现成功时间在【微信-服务-钱包-账单】中查看明细
									</li>
									<li>
										部分提现失败：微信返回的失败原因
									</li>
								</ul>
							</div>
						</div>
						<!-- 时间线 -->
						<div class="withdrawal-line">
							<el-timeline>
								<el-timeline-item v-for="(activity, index) in data.rightTimeData" :key="index"
									:size="index === 0 ? 'large' : ''"
									:color="index === 0 ? 'rgba(5, 124, 152, 0.08)' : 'transparent'">
									<div class="timeline-box">
										<div class="timeline-box-label">
											<div class="timeline-label-position">
												{{ activity.content }}
											</div>
											<div class="timeline-label-time">
												{{ activity.timestamp }}
											</div>
										</div>
										<div class="timeline-box-time" style="color:#F87700">
											<div class="describe" v-if="activity.stat != 1">{{ activity.amount }} 元
												<span v-if="activity.failReason && activity.failReason != ''">({{
													activity.failReason
												}})</span>
												<span v-if="activity.stat == 3"
													style="color: #4E5969;font-family: 'PingFang SC';font-size: 14px;font-weight: 400;">(原路退回)</span>
											</div>
											<div class="describe" v-else> <span style="visibility: hidden;">slot</span>
											</div>
										</div>
									</div>
								</el-timeline-item>
							</el-timeline>
						</div>
					</div>
					<div class="footer-line-close-btn">
						<el-button @click="isShowDrawer = false">关闭</el-button>
					</div>
				</div>
			</el-drawer>
		</div>

		<!-- 详情 -->
		<div class="showDrawer-detail">
			<el-drawer title="详情" :visible.sync="isShowDetailDrawer" direction="rtl" :modal="false" :size="1208"
				:destroy-on-close="true">
				<div class="drawer-tabs">
					<el-tabs v-model="activeName" @tab-click="handleSelectTab">
						<el-tab-pane label="车主信息" name="first">
							<userDetail v-if="showTabItem" :userId="sendUserId" :mobile="sendMobile"
								@updateList="getWalletListFn" />
						</el-tab-pane>
						<el-tab-pane label="账户流水" name="second">
							<journalDetail v-if="!showTabItem" :mobile="sendMobile" />
						</el-tab-pane>
					</el-tabs>
				</div>
				<div>
					<div class="footer-close-btn">
						<el-button @click="isShowDetailDrawer = false">关闭</el-button>
					</div>
				</div>
			</el-drawer>
		</div>
	</div>
</template>

<script setup>
import {
	ref,
	reactive,
	toRefs,
} from "vue";
import {
	getWalletList,
	getTotalList,
	getWithdraw,
} from '@/api/journal'
import { getToken } from "@/utils/auth";

import userDetail from '../../registerUser/components/userDetail'
import journalDetail from '../../registerUser/components/journal'

import {
	Message,
	MessageBox
} from "element-ui";

const isShowDrawer = ref(false)
//详情

const isShowDetailDrawer = ref(false);
const showTabItem = ref(true)
const activeName = ref('first')
const sendUserId = ref('')
const sendMobile = ref('')

const rightDrawerObj = reactive({
	mobile: '',
	rightDrawerState: null,
})
const paginationObj = reactive({
	page: 1,
	listPageSize: 20,
	total: 0,
})
const tableLoading = ref(false);
const searchFormRef = ref(null);
const downloadUrl = ref(process.env.VUE_APP_BASE_API);
const searchTimeData = ref([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()])

const data = reactive({
	totalListForm: {
		category: 1,
		startTime: transformTime(searchTimeData.value[0]),
		endTime: transformTime(searchTimeData.value[1]),
	},
	searchForm: {
		category: 1,
		mobile: '',
		classify: '',
		stat: '',
		// timeRange: [new Date(new Date() - 24 * 60 * 60 * 1000), new Date()],
		timeRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
		startTime: '',
		endTime: '',
		pageNo: 1,
		pageSize: 20,
	},
	pickerOptions: {
		shortcuts: [
			{
				text: '本月',
				onClick(picker) {
					const end = new Date();
					const start = new Date(new Date().setDate(1)); // 当月第一天
					picker.$emit('pick', [start, end]);
				}
			},
			{
				text: '上月',
				onClick(picker) {
					const oDate = new Date();
					var year = oDate.getFullYear();
					var month = oDate.getMonth();
					var start, end;
					if (month == 0) {
						year--
						start = new Date(year, 11, 1)
						end = new Date(year, 11, 31)
					} else {
						start = new Date(year, month - 1, 1)
						end = new Date(year, month, 0);
					}
					picker.$emit('pick', [start, end]);
				}
			},
			{
				text: '上上月',
				onClick(picker) {
					const now = new Date();
					const year = now.getFullYear();
					const month = now.getMonth();
					const start = new Date(year, month - 2, 1); // 上上月第一天
					const end = new Date(year, month - 1, 0); // 上上月最后一天
					picker.$emit('pick', [start, end]);
				}
			}
		],
		onPick: ({
			maxDate,
			minDate
		}) => {
			searchForm.timeRange = minDate.getTime()
			if (maxDate) {
				searchForm.timeRange = ''
			}
		},
		disabledDate: (time) => {
			if (searchForm.timeRange !== '') {
				const one = 30 * 24 * 3600 * 1000
				const minTime = searchForm.timeRange - one
				const maxTime = searchForm.timeRange + one
				return time.getTime() < minTime || time.getTime() > maxTime
			}
		}
	},
	tableParams: {
		category: 1,
		classify: null,
		endTime: '',
		mobile: '',
		startTime: '',
		stat: '',
		pageNo: 1,
		pageSize: 20,
	},
	marketList: [{
		classify: 1,
		type: '账户充值'
	},
	{
		classify: 3,
		type: '充电消费'
	},
	{
		classify: 80,
		type: '订单退款'
	},
	{
		classify: 90,
		type: '账户提现'
	}],
	statusList: [{
		stat: 0,
		type: '失败'
	},
	{
		stat: 1,
		type: '提现中'
	},
	{
		stat: 2,
		type: '部分成功'
	},
	{
		stat: 3,
		type: '成功'
	},
	],
	rightTimeData: [],
	totalData: {},
	tableData: []
});

const {
	totalListForm,
	searchForm,
	pickerOptions,
	marketList,
	statusList,
	tableParams,
	totalData,
	tableData
} = toRefs(data);

function handleTimeData(date) { //查询统计
	searchForm.value.startTime = transformTime(date[0])
	searchForm.value.endTime = transformTime(date[1])
	searchForm.value.mobile = ''
	searchForm.value.stat = ''
	searchForm.value.classify = ''
	totalListForm.value = {
		...searchForm.value
	}
	getTotalListFn()
}

function getWalletListFn() {
	tableLoading.value = true;
	getWalletList(tableParams.value)
		.then((res) => {
			if (res.data && res.data.records.length > 0) {
				tableData.value = [...res.data.records];
			}
			paginationObj.total = res.data.total
			tableLoading.value = false;
		})
		.catch((e) => {
			tableLoading.value = false;
		});
}
getWalletListFn() //获取表格数据

function getTotalListFn() {
	const currentDate = new Date();
	const year = currentDate.getFullYear();//获取当前年
	const month = String(currentDate.getMonth() + 1).padStart(2, "0");//获取当前月
	const firstDay = "01";//日
	const beforeDay = `${year}-${month}-${firstDay}`

	const dd = String(currentDate.getDate()).padStart(2, '0');
	const mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // 1月是0!
	const yyyy = currentDate.getFullYear();
	const today = yyyy + '-' + mm + '-' + dd;


	getTotalList(totalListForm.value)
		.then((res) => {
			if (res.data) {
				totalData.value = res.data
			}
		})
		.catch((e) => { });
}
getTotalListFn() //获取汇总数据

//车主档案详情
function handleSelectTab(e) {
	showTabItem.value = e.index == 0 ? true : false
}
function handleisShowDetailDrawer(row) {
	sendMobile.value = row.mobile
	sendUserId.value = row.id
	isShowDetailDrawer.value = true
}

// 标准日转化为年月日
function transformTime(date) {
	const d = new Date(date);
	return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
}

function searchData() { //查询
	if (!searchForm.value.timeRange) {
		Message('请选择交易时间')
		return
	}
	if (searchForm.value.timeRange[0] == '') {
		Message('请选择开始交易时间')
		return
	}
	if (searchForm.value.timeRange[1] == '') {
		Message('请选择结束交易时间')
		return
	}
	searchForm.value.startTime = transformTime(searchForm.value.timeRange[0])
	searchForm.value.endTime = transformTime(searchForm.value.timeRange[1])
	if (searchForm.value.mobile != '') {
		if (!/^0|[1-9]\d*$/.test(searchForm.value.mobile)) {
			Message('请输入手机号')
			return
		}
	}

	tableParams.value = {
		...searchForm.value
	}
	tableData.value = []
	getWalletListFn()
}

function resetData() { //重置
	searchFormRef.value.resetFields()
	searchForm.value.startTime = ''
	searchForm.value.endTime = ''
	tableParams.value = {
		...searchForm.value
	}
	tableData.value = []
	getWalletListFn()

}

function handleSizeChange(val) {
	paginationObj.listPageSize = val;
	tableParams.value.pageSize = val
	getWalletListFn();
}

function handleCurrentChange(val) {
	paginationObj.pageNo = val;
	paginationObj.page = val;
	tableParams.value.pageNo = val
	getWalletListFn();
}

function handleExportFile() {
	MessageBox.confirm("确定将数据导出吗？", "提示", {
		confirmButtonText: "确定",
		cancelButtonText: "取消",
		type: "warning",
	}).then(() => {
		let xhr = new XMLHttpRequest();
		xhr.open("post", downloadUrl.value + "/wallet/bills/list/download", true);
		xhr.responseType = "blob";
		xhr.setRequestHeader("token", getToken());
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.onload = function () {
			if (this.status == 200) {
				var blob = this.response;
				var a = document.createElement("a");
				var url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = "账户余额明细_" + transformTime(searchForm.value.timeRange[0]) + '至' + transformTime(searchForm.value.timeRange[1]);
				a.click();
			}
			window.URL.revokeObjectURL(url);
		};
		xhr.send(JSON.stringify(getSearchParams()));

	});
}
function getSearchParams() {
	tableParams.value.startTime = transformTime(searchForm.value.timeRange[0])
	tableParams.value.endTime = transformTime(searchForm.value.timeRange[1])
	return tableParams.value;
}

function handleShowRightData(row) {
	console.log(row, 456);
	if (row.classify != 90) return
	getWithdrawFn(row.tradeNo, row.state) //TX1WX000000001240802100005 
}

function getWithdrawFn(tradeNo, state) {
	getWithdraw({ tradeNo })
		.then((res) => {
			if (res.data.data && res.data.data.length > 0) {
				res.data.data.forEach((item, index) => {
					item.timestamp = item.time
					item.size = 'large'
					item.stat = item.stat
					item.failReason = item.failReason
					if (item.stat == -1) {
						item.content = '发起申请'
					} else if (item.stat == 0) {
						item.content = '提现失败'
					} else if (item.stat == 1) {
						item.content = '提现中'
					} else if (item.stat == 3) {
						item.content = '提现成功'
					}

					if (index == 0) {
						item.type = 'primary'
						item.icon = '@/assets/time-line.png'
					} else {
						item.color = '#00000040'
					}
				})
				data.rightTimeData = res.data.data

				rightDrawerObj.mobile = res.data.mobile
				rightDrawerObj.rightDrawerState = state
				isShowDrawer.value = true
			}
		})
		.catch((e) => { });
}
</script>

<style lang="scss" scoped>
.journal-body {
	height: 100%;
	margin-top: 56px;

	.balance-body {
		width: 100%;
		height: 100%;
		padding: 0 16px;

		.data-container {
			.middle-data {
				margin: 20px 0 12px;

				.left-content,
				.right-content {
					padding: 16px;
					border-radius: 8px;
					background-color: #fff;

					.data-datail {
						margin-top: 12px;
						padding: 16px;
						border-radius: 8px;
						border: 1px solid var(--PC--, #E5E6EB);
					}

					.item-data {
						display: flex;
						align-items: center;

						.item-title {
							margin-bottom: 8px;
							color: #4E5969;
							font-size: 14px;
							font-weight: 400;
						}

						.item-val {
							color: #1D2129;
							font-family: "D-DIN-PRO";
							font-size: 20px;
							font-weight: 500;
						}
					}
				}

				.left-content,
				.right-content {

					// ::v-deep .el-input__icon.el-icon-date {
					// 	display: none;
					// }
					.left-title,
					.right-title {
						color: var(--PC-, #1D2129);
						font-family: "PingFang SC";
						font-size: 16px;
						font-weight: 500;
						line-height: 22px;
					}
				}

				.left-icon {
					margin-right: 8px;

					.img-content {
						width: 48px;
						height: 48px;
					}
				}
			}
		}

	}

	.search-content {
		padding: 16px 16px 0 0;
		border-radius: 8px;
		background-color: #fff;
	}

	.table-body {
		margin-top: 12px;
		padding: 16px;
		border-radius: 8px;
		background-color: #fff;
	}

	.table-list {
		margin-top: 20px;

		.table-state {
			line-height: normal;
			display: inline-flex;
			align-items: center;
			padding: 4px 8px;
			border-radius: 4px;
			cursor: pointer;
			color: #DF3737;
			background: rgba(8, 171, 103, 0.06);

			img {
				margin-left: 2px;
				width: 12px;
				height: 12px;
			}
		}
	}

	//右侧弹框
	.drawer-phone {
		padding: 16px 24px;
		color: #1D2129;
		font-size: 16px;
		font-weight: 500;
		background: linear-gradient(91deg, #EBF5F7 0.07%, #F5F2F0 100%);

		img {
			vertical-align: middle;
			width: 24px;
			height: 24px;
		}
	}

	.border-style {
		height: 10px;
		background-color: #F5F2F0;

		.radiu-body {
			height: 10px;
			border-radius: 10px 10px 0 0;
			background-color: #fff;
		}
	}

	.drawer-detail {
		padding: 10px 24px 20px;
		border-radius: 8px 8px 0px 0px;
		background: var(--PC-, #FFF);

		.tips-body {
			padding: 8px 12px;
			border-radius: 4px;
			background: rgba(248, 119, 0, 0.08);
		}

		.tips-body2 {
			padding: 8px 12px;
			border-radius: 4px;
			background: rgba(223, 55, 55, 0.08);
			color: var(--PC-, #DF3737);
			font-size: 14px;
			font-weight: 400;
		}


		.detail-tips {
			padding: 8px 12px;
			border-radius: 4px;
			color: var(--PC-, #F87700);
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;

			ul {
				list-style: unset;
			}
		}

		.withdrawal-line {
			margin-top: 24px;

			.timeline-box {
				.timeline-box-label {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.timeline-label-position {
						color: var(--PC-, #1D2129);
						font-size: 16px;
						font-weight: 500;
					}

					.timeline-label-time {
						color: var(--PC-, #86909C);
						font-size: 14px;
						font-weight: 400;
					}

					.timeline-box-time {
						color: var(--PC-, #4E5969);
						font-size: 14px;
						font-weight: 500;
					}
				}
			}

			.describe {
				margin-top: 10px;
				color: var(--PC-, #4E5969);
				font-size: 14px;
				font-weight: 500;

				span {
					color: var(---, rgba(0, 0, 0, 0.45));
					font-family: "PingFang SC";
					font-size: 14px;
					font-weight: 400;
				}
			}
		}
	}

	.footer-line-close-btn {
		position: absolute;
		width: 100%;
		bottom: 0;
		right: 0;
		text-align: right;
		border-top: 1px solid #E5E6EB;
		padding: 10px 24px;
	}
}


::v-deep .el-dialog__close {
	color: #86909C;
}

::v-deep .el-drawer {
	border-radius: 8px 0 0 8px;
	// background: linear-gradient(91deg, #EBF5F7 0.07%, #F5F2F0 100%);
}

::v-deep .el-drawer__close-btn {
	font-size: 18px;
}

::v-deep .el-drawer__header {
	margin-bottom: 0px !important;
	padding: 16px 24px 16px;
	color: #1D2129;
	background-color: #fff;
	font-size: 16px;
	font-weight: 500;
}

// 时间线
::v-deep .el-timeline-item__tail {
	position: absolute;
	left: 7px;
	top: 30%;
	height: 55%;
	border-left: 1px solid #e4e7ed;
}

::v-deep .el-timeline-item__node::before {
	content: "";
	width: var(--BorderRadius-borderRadius, 6px);
	height: var(--BorderRadius-borderRadius, 6px);
	flex-shrink: 0;
	background: #c9cdd4;
	border-radius: 50%;
}

::v-deep .el-timeline-item__node-- {
	content: "";
	width: var(--BorderRadius-borderRadius, 6px);
	height: var(--BorderRadius-borderRadius, 6px);
	flex-shrink: 0;
	background: transparent;
	width: 14px;
	height: 14px;
	left: 0px;
	top: -2px;
	border-radius: 50%;
}

::v-deep .el-timeline-item__node--large::before {
	content: "";
	width: var(--BorderRadius-borderRadius, 6px);
	height: var(--BorderRadius-borderRadius, 6px);
	flex-shrink: 0;
	background: var(--current-color);
	border-radius: 50%;
}

::v-deep .el-timeline {
	width: 100%;
}

/**表格样式通用 */
::v-deep .el-table td {
	/* 设置字体大小 */
	font-size: 14px;
}

.showDrawer-detail {

	//详情弹框
	::v-deep .el-drawer__header {
		border-bottom: 1px solid #e5e6eb;
	}

	::v-deep .el-tabs__content {
		background-color: '#fff' !important;
	}

	::v-deep .el-tabs__header {
		position: relative;
		z-index: 0;
		width: 100%;
	}

	.footer-close-btn {
		position: absolute;
		width: 100%;
		bottom: 0;
		right: 0;
		text-align: right;
		border-top: 1px solid #E5E6EB;
		padding: 10px 24px;
		background-color: #fff;
	}
}
</style>