var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          staticStyle: { top: "160px" },
          on: { "tab-click": _setup.handleTabClick },
          model: {
            value: _setup.activeName,
            callback: function ($$v) {
              _setup.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "账户余额", name: "first" } },
            [_c(_setup.balanceTab)],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "奖励金", name: "second" } },
            [_c(_setup.rewardTab)],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }