<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleTabClick" style="top: 160px;">
            <el-tab-pane label="账户余额" name="first">
                <balanceTab />
            </el-tab-pane>
            <el-tab-pane label="奖励金" name="second">
                <rewardTab />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script setup>
import {
    ref,
    reactive,
    toRefs
} from "vue";

import balanceTab from './components/balance'
import rewardTab from './components/reward'

const activeName = ref('first')

function handleTabClick(tab, event) {
    console.log(tab, event, 88);
}

</script>

<style lang="scss" scoped>
::v-deep .el-tabs__header{
    position: fixed;
    z-index: 1;
    width: 100%;
}

::v-deep .el-tabs {
    margin-right: 0 !important;
}

::v-deep .el-tabs__nav-wrap {
    background-color: #fff !important;
}

::v-deep .el-tabs__nav-scroll {
    padding-left: 16px !important;
}

::v-deep .el-tabs__nav-wrap::after {
    background-color: transparent !important;
}

::v-deep .el-tabs__content {
    background-color: #F7F8FA !important;
}

</style>