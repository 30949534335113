<template>
  <div class="main">
    <div class="content-box">
      <el-input
        maxlength="15"
        style="width:240px;"
        placeholder="按文件名称搜索"
        v-model="fileRealName"
      >
        <el-button
          slot="suffix"
          @click="searchData"
          type="primary"
          icon="el-icon-search"
          style="border-radius:0 4px 4px 0;margin-right:-5px"
          >搜索</el-button
        >
      </el-input>

      <el-button
        @click="addHandler"
        type="primary"
        style="margin-left:20px;"
        icon="el-icon-plus"
        >新增</el-button
      >
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>

        <el-table-column
          prop="fileRealName"
          label="真实文件名称"
          align="center"
          min-width="220"
        ></el-table-column>

        <el-table-column
          prop="fileType"
          label="文件描述"
          align="center"
          min-width="180"
        ></el-table-column>

        <el-table-column
          prop="fileUrl"
          label="文件路径"
          align="center"
          min-width="200"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          min-width="130"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column
          prop="fileUrl"
          label="图片"
          align="center"
          min-width="180"
        >
          <template slot-scope="scope">
            <el-image  class="img" :src="scope.row.fileUrl" :preview-src-list="[scope.row.fileUrl]" fit="contain" />
          </template>
        </el-table-column>

        <!-- <el-table-column prop="state" label="状态" align="center" min-width="80">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.state"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                active-value="1"
                                @change="changeStateHandler($event,scope.row)"
                                inactive-value="0"
                        ></el-switch>
                    </template>
                </el-table-column> -->
        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <div class="editCell">
              <!-- <el-link @click="editCardHandler(scope.row)">编辑</el-link> -->
              <el-button
                 
                type="danger"
                @click="deteteHandlet(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="isAdd ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-form ref="form" :model="formData" label-width="130px">
        <el-form-item class="nav-form-item" label="文字描述" prop="title">
          <el-input
            maxlength="256"
            v-model="formData.fileType"
            style="width:300px;"
            placeholder="请用文字描述一下文件用于哪里"
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="上传" prop="title">
          <el-upload
            ref="upload"
            class="upload-demo"
            drag
            :action="uploadUrl"
            :headers="headers"
            :data="formData"
            :on-success="handleUploadSuccess"
            :show-file-list="false"
            multiple
          >
            <i class="el-icon-upload" style="width: 300px;"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item class="nav-form-item" label="图片" prop="title">
          <img v-if="imageUrl" :src="imageUrl" style="width: 150px;" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimHandler">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addAdverts, useAdvert, editAdvert, addAdvert } from "@/api/advert";

import { getFileList, delFile } from "@/api/file";
import { getCodesByParent } from "@/api/common";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + "/sysFile/uploadImg",
      headers: {
        token: getToken(),
      },

      imageUrl: "",
      dialogVisible: false,
      operatorId: "",
      date: "",
      isAdd: true,
      search: "",
      tableData: [],
      page: 1,
      pageSize: 10,
      loading: true,
      fileType: "",
      formData: {
        fileType: "",
        business: "advert",
      },
      total: 0,

      options: [
        {
          value: "1",
          label: "最近十五天",
        },
        {
          value: "2",
          label: "最近十天",
        },
      ],

      pageData: [],
      fileRealName: "",
    };
  },
  created() {
    this.getPages();
    this.getDataList();
  },
  methods: {
    handleUploadSuccess(res, file) {
      // this.formData.fileId = res.data.imgID
      this.imageUrl = URL.createObjectURL(file.raw);
      this.$refs.upload.clearFiles();
      this.fileType = "";
      // this.dialogVisible = false;
      // this.getDataList();
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let param = {
        fileRealName: this.fileRealName,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getFileList(param).then((res) => {
        if (res.data.records) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    getPages() {
      let param = {
        parentCode: 1,
      };
      getCodesByParent(param).then((res) => {
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.pageData = [...res.data];
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    addHandler() {
      this.isAdd = true;
      this.formData.fileType = "";
      // this.formData.showPage = ''
      // this.formData.title = ''
      // this.formData.url = ''
      this.imageUrl = "";
      this.dialogVisible = true;
    },
    confimHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add();
          } else {
            this.editCard();
          }
        }
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.formData.id = row.id;
      // this.formData.fileId = row.fileId
      // this.formData.title = row.title
      // this.formData.url = row.url
      // this.imageUrl = row.fileUrl;
      this.dialogVisible = true;
    },
    editCard() {
      var params = this.formData;
      params.showPage = null;
      params.showPageList = null;

      editAdvert(params).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    add() {
      this.dialogVisible = false;
      this.getDataList();
      // addAdvert(this.formData).then(res => {
      //     if (res.message.code == 0) {
      //         this.$message({
      //             message: '操作成功',
      //             type: 'success'
      //         })
      //         this.dialogVisible = false
      //         this.getDataList()
      //     } else {
      //         this.$message.error(res.data)
      //     }
      // })
    },
    deteteHandlet(row) {
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: row.id,
        };
        delFile(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
    changeStateHandler($event, row) {
      let param = {
        state: $event,
        id: row.id,
      };
      useAdvert(param).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}

.img {
  height: 50px;

  .img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
