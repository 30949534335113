<template>
    <div class="journal-body">
        <div class="balance-body">
            <div class="data-container">
                <div class="middle-data">
                    <el-row :gutter="12">
                        <el-col :span="16">
                            <div class="left-content">
                                <div style="display: flex;justify-content: space-between;align-items: center;">
                                    <div class="left-title">流水分类统计</div>
                                    <div class="left-time">
                                        <el-date-picker v-model="searchTimeData" type="daterange"
                                            :picker-options="pickerOptions" start-placeholder="开始日期"
                                            end-placeholder="结束日期" align="right" :clearable="false"
                                            style="width: 320px;" @change="handleTimeData">
                                            <template slot="range-separator" scope="{ start, end }">
												<img src="@/assets/SwapRight.svg" alt="">
											</template>
                                        </el-date-picker>
                                    </div>
                                </div>
                                <div class="data-datail">
                                    <el-row>
                                        <el-col :span="6">
                                            <div class="item-data">
                                                <div class="left-icon">
                                                    <img src="@/assets/journal/reward-data4.png" class="img-content" />
                                                </div>
                                                <div>
                                                    <div class="item-title">平台赠送金额 (元)</div>
                                                    <div class="item-val">{{ totalData.giftAmount }}</div>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="item-data">
                                                <div class="left-icon">
                                                    <img src="@/assets/journal/reward-data1.png" class="img-content" />
                                                </div>
                                                <div>
                                                    <div class="item-title">充值奖励金额 (元)</div>
                                                    <div class="item-val">{{ totalData.rewardAmount }}</div>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="item-data">
                                                <div class="left-icon">
                                                    <img src="@/assets/journal/reward-data3.png" class="img-content" />
                                                </div>
                                                <div>
                                                    <div class="item-title">奖励抵扣金额 (元)</div>
                                                    <div class="item-val">{{ totalData.chargeAmount }}</div>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="6">
                                            <div class="item-data">
                                                <div class="left-icon">
                                                    <img src="@/assets/journal/reward-data2.png" class="img-content" />
                                                </div>
                                                <div>
                                                    <div class="item-title">订单退款金额 (元)</div>
                                                    <div class="item-val">{{ totalData.refundAmount }}</div>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="right-content">
                                <div style="display: flex;justify-content: space-between;align-items: center;">
                                    <div class="right-title">平台账户统计</div>
                                    <div style="height: 32px;width: 20px;"></div>
                                </div>
                                <div class="data-datail">
                                    <el-row>
                                        <el-col :span="12">
                                            <div class="item-data">
                                                <div class="left-icon">
                                                    <img src="@/assets/journal/reward-data5.png" class="img-content" />
                                                </div>
                                                <div>
                                                    <div class="item-title">月初奖励金总余额 (元)</div>
                                                    <div class="item-val">{{ totalData.beginBalance }}</div>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="item-data">
                                                <div class="left-icon">
                                                    <img src="@/assets/journal/reward-data6.png" class="img-content" />
                                                </div>
                                                <div>
                                                    <div class="item-title">最新奖励金总余额 (元)</div>
                                                    <div class="item-val">{{ totalData.latestBalance }}</div>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>

            <div class="search-content">
                <el-form ref="searchFormRef" :model="searchForm" label-suffix=":" label-width="14px">
                    <div style="display: flex;">
                        <el-row :gutter="8" type="flex" style="flex:1;flex-wrap:wrap;">
                            <el-col :span="8">
                                <el-form-item prop="mobile">
                                    <div style="display: flex;">
                                        <span style="margin-right: 8px; white-space: nowrap;">车主手机</span>
                                        <el-input maxlength="11" v-model.trim="searchForm.mobile" placeholder="请输入"
                                            clearable></el-input>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="classify">
                                    <div style="display: flex;">
                                        <span style="margin-right: 8px; white-space: nowrap;">交易类型</span>
                                        <el-select v-model="searchForm.classify" filterable placeholder="请选择"
                                            style="width: 100%;" clearable>
                                            <el-option v-for="(item, index) in marketList" :key="index"
                                                :label="item.type" :value="item.classify">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item ref="timeRange" prop="timeRange">
                                    <div style="display: flex;">
                                        <span style="margin-right: 8px; white-space: nowrap;">交易时间</span>
                                        <el-date-picker v-model="searchForm.timeRange" type="daterange"
                                            :picker-options="pickerOptions" start-placeholder="开始日期"
                                            end-placeholder="结束日期" align="right" :clearable="false">
                                            <template slot="range-separator" scope="{ start, end }">
												<img src="@/assets/SwapRight.svg" alt="">
											</template>
                                        </el-date-picker>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <div style="margin:0 12px; width: 1px;height: 32px;background-color: #E5E6EB;">
                            </div>
                        </el-row>
                        <el-row>
                            <div style="display: flex;">
                                <el-button @click="searchData" type="primary">查询</el-button>
                                <el-button @click="resetData" plain style="margin-left:8px;">重置</el-button>
                            </div>
                        </el-row>
                    </div>
                </el-form>
            </div>
            <!-- 列表 -->
            <div class="table-body">
                <div>
                    <el-button @click="handleOpenDialog" type="primary">平台赠送</el-button>
                </div>
                <div class="table-list">
                    <el-table v-loading="tableLoading" :data="tableData"
                        :header-cell-style="{ background: '#F7F8FA !important', color: '#1D2129  !important' }"
                        style="width: 100%">
                        <el-table-column type="index" label="序号" width="60"></el-table-column>
                        <el-table-column prop="tradeNo" label="流水号" min-width="100" show-overflow-tooltip>
                            <template slot-scope="{row}">
                                <span v-if="row.tradeNo && row.tradeNo != ''">{{ row.tradeNo }}</span>
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="tradeTime" label="交易时间" min-width="100"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="mobile" label="车主手机 " min-width="100">
                            <template slot-scope="{row}">
                                <span style="color: #057C98;cursor: pointer;" @click="handleisShowDetailDrawer(row)">{{ row.mobile }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="classify" label="交易类型" min-width="90">
                            <template slot-scope="{row}">
                                <span v-if="row.classify == 7" style="display: inline-flex;align-items: center;">
                                    平台赠送
                                    <el-tooltip class="item" effect="dark" :content="row.remarks" placement="top">
                                        <img src="@/assets/journal/QuestionCircle.svg"
                                            style="margin-left: 4px; width: 12px;height: 12px;cursor: pointer;" alt="">
                                    </el-tooltip>
                                </span>
                                <span v-if="row.classify == 3">奖励抵扣</span>
                                <span v-else-if="row.classify == 71">充值奖励</span>
                                <span v-else-if="row.classify == 80" style="display: inline-flex;align-items: center;">订单退款
                                    <el-tooltip class="item" effect="dark" :content="row.remarks" placement="top">
                                        <img src="@/assets/journal/QuestionCircle.svg"
                                            style="margin-left: 4px; width: 12px;height: 12px;cursor: pointer;" alt="">
                                    </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="amount" label="交易金额 (元)" min-width="90">
                            <template slot-scope="{row}">
                                <span :style="{ color: row.amount < 0 ? '#DF3737' : '#08AB67' }">{{ row.amount }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="beforeAmount" label="交易前金额 (元) " min-width="100"></el-table-column>
                        <el-table-column prop="afterAmount" label="交易后金额 (元) " min-width="100"></el-table-column>
                        <el-table-column prop="orderNo" label="关联订单" min-width="120" show-overflow-tooltip>
                            <template slot-scope="{row}">
                                <span v-if="row.orderNo && row.orderNo != ''" style="cursor: pointer;color: #057C98;">
                                    <router-link :to="`/order/orderDetail/${row.orderNo}`">{{ row.orderNo
                                        }}</router-link>
                                </span>
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="paginationObj.page" :page-size="paginationObj.listPageSize"
                        layout="total, prev, pager, next,sizes, jumper" :total="paginationObj.total"></el-pagination>
                </div>
            </div>
        </div>
        <!-- 平台赠送 -->
        <el-dialog title="平台赠送" :visible.sync="dialogVisibleDonate" @close="handleDialogDonateClose"
            :close-on-click-modal="false" width="448px">
            <div class="donate-title"> <img src="@/assets/donate-icon.png" alt=""> 平台赠送金额可用于充电消费，含电费和服务费</div>
            <div class="donate-form">
                <el-form ref="formDataDonateRef" :model="formDataDonate" label-position="left"
                    :rules="formDataDonateRule">
                    <div>
                        <el-form-item class="nav-form-item" prop="mobile">
                            <div> <span style="color:#DF3737;">*</span> 车主手机</div>
                            <div class="list-item">
                                <div class="item-input">
                                    <el-input maxlength="11" v-model="formDataDonate.mobile" placeholder="请输入"
                                        @input="handleDonateInput" @clear="handleClear" show-word-limit clearable />
                                </div>
                                <div class="item-money">奖励金余额 (元)：{{ showLatestBalance == '' ?
                                    '--' : showLatestBalance
                                    }}</div>
                            </div>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item class="nav-form-item" prop="giftAmount">
                            <div> <span style="color:#DF3737;">*</span> 赠送金额 (元)</div>
                            <div class="list-item">
                                <div class="item-input">
                                    <el-input v-model="formDataDonate.giftAmount" placeholder="请输入" show-word-limit @input="handleInput"
                                        autocomplete="off" clearable />
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item class="nav-form-item" style="margin-bottom: 0;">
                            <div> <span style="color:#DF3737;">*</span> 赠送原因</div>
                            <div class="list-item">
                                <div>
                                    <el-form-item>
                                        <el-radio-group v-model="formDataDonate.giftReasonSel" @change="changeNodeType">
                                            <el-radio :label="1"
                                                style="margin-right: 16px;color: #4E5969;">测试</el-radio>
                                            <el-radio :label="2" style="margin-right: 8px;color: #4E5969;">其他</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="item-input" v-if="isValidateView">
                                    <el-form-item prop="giftReasonText">
                                        <el-input type="text" maxlength="30" v-model="formDataDonate.giftReasonText"
                                            placeholder="请输入" clearable />
                                    </el-form-item>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item class="nav-form-item" prop="password">
                            <div> <span style="color:#DF3737;">*</span> 登录密码</div>
                            <div class="list-item">
                                <div class="item-input">
                                    <el-input type="password" v-model="formDataDonate.password"
                                        autocomplete="new-password" placeholder="请输入账号登录密码验证本人操作" show-word-limit
                                        clearable />
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleDonate = false">取 消</el-button>
                <el-button type="primary" @click="handleSureDonate" :disabled="!formIsValid">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 详情 -->
		<div class="showDrawer-detail">
			<el-drawer title="详情" :visible.sync="isShowDetailDrawer" direction="rtl" :modal="false" :size="1208"
				:destroy-on-close="true">
				<div class="drawer-tabs">
					<el-tabs v-model="activeName" @tab-click="handleSelectTab">
						<el-tab-pane label="车主信息" name="first">
							<userDetail v-if="showTabItem" :userId="sendUserId" :mobile="sendMobile"
								@updateList="getWalletListFn" />
						</el-tab-pane>
						<el-tab-pane label="账户流水" name="second">
							<journalDetail v-if="!showTabItem" :mobile="sendMobile" />
						</el-tab-pane>
					</el-tabs>
				</div>
				<div>
					<div class="footer-close-btn">
						<el-button @click="isShowDetailDrawer = false">关闭</el-button>
					</div>
				</div>
			</el-drawer>
		</div>
    </div>
</template>

<script setup>
import {
    ref,
    reactive,
    toRefs,
    watch,
    computed
} from "vue";

import {
    getWalletList,
    getTotalList,
    getRewardGift
} from '@/api/journal'
import { Message } from "element-ui";

import userDetail from '../../registerUser/components/userDetail'
import journalDetail from '../../registerUser/components/journal'

const dialogVisibleDonate = ref(false)
const tableLoading = ref(false);
const paginationObj = reactive({
    page: 1,
    listPageSize: 20,
    total: 0,
})
const searchFormRef = ref(null);
const formDataDonateRef = ref(null);
const isValidateView = ref(false);
const formIsValid = ref(true);
const searchTimeData = ref([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()])
//详情
const isShowDetailDrawer = ref(false);
const showTabItem = ref(true)
const activeName = ref('first')
const sendUserId = ref('')
const sendMobile = ref('')

const data = reactive({
    totalListForm: {
		category: 2,
		startTime: transformTime(searchTimeData.value[0]),
		endTime: transformTime(searchTimeData.value[1]),
	},
    searchForm: {
        category: 2,
        mobile: '',
        classify: '',
        stat: '',
        timeRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 20,
    },
    tableParams: {
        category: 2,
        classify: null,
        endTime: '',
        mobile: '',
        startTime: '',
        stat: '',
        pageNo: 1,
        pageSize: 20,
    },
    pickerOptions: {
        shortcuts: [
        {
            text: '本月',
            onClick(picker) {
                const end = new Date();
                const start = new Date(new Date().setDate(1)); // 当月第一天
                picker.$emit('pick', [start, end]);
            }
        },
            {
                text: '上月',
                onClick(picker) {
                    const oDate = new Date();
                    var year = oDate.getFullYear();
                    var month = oDate.getMonth();
                    var start, end;
                    if (month == 0) {
                        year--
                        start = new Date(year, 11, 1)
                        end = new Date(year, 11, 31)
                    } else {
                        start = new Date(year, month - 1, 1)
                        end = new Date(year, month, 0);
                    }

                    picker.$emit('pick', [start, end]);
                }
            },
            {
                text: '上上月',
                onClick(picker) {
                    const now = new Date();
                    const year = now.getFullYear();
                    const month = now.getMonth();
                    const start = new Date(year, month - 2, 1); // 上上月第一天
                    const end = new Date(year, month - 1, 0); // 上上月最后一天
                    picker.$emit('pick', [start, end]);
                }
            }
        ],
        onPick: ({ maxDate, minDate }) => {
            searchForm.timeRange = minDate.getTime()
            if (maxDate) {
                searchForm.timeRange = ''
            }
        },
        disabledDate: (time) => {
            if (searchForm.timeRange !== '') {
                const one = 30 * 24 * 3600 * 1000
                const minTime = searchForm.timeRange - one
                const maxTime = searchForm.timeRange + one
                return time.getTime() < minTime || time.getTime() > maxTime
            }
        }
    },
    marketList: [
        {
            classify: 7,
            type: '平台赠送'
        },
        {
            classify: 71,
            type: '充值奖励'
        },
        {
            classify: 80,
            type: '订单退款'
        },
        {
            classify: 3,
            type: '奖励抵扣'
        },
    ],
    formDataDonate: {
        giftAmount: '',
        giftReasonSel: 1,
        giftReason: '',
        mobile: '',
        password: '',
        giftReasonText: '',
    },
    formDataDonateRule: {
        mobile: [
            { required: true, message: "请输入手机号码", trigger: "blur" },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确手机号码', trigger: 'blur' }
        ],
        giftAmount: [
            { required: true, message: "请输入赠送金额", trigger: "blur" },
            // { pattern: /^\d+(\.\d+)?$/, message: '请输入数字', trigger: 'blur' },
            {
                validator: (rule, value, callback) => {
                    const decimalPlaces = (value.split('.')[1] || '').length;
                    if (decimalPlaces > 2) {
                        callback(new Error('小数点后不能超过两位'));
                    } else if (value <= 0) {
                        callback(new Error('赠送金额不能小于等于0'));
                    } else if (value > 500) {
                        callback(new Error('不能超出最大赠送余额500元'));
                    } else {
                        callback();
                    }
                },
                trigger: 'blur'
            }
        ],
        password: [
            { required: true, message: "请输入密码", trigger: "blur" },
        ],
        giftReasonText: [
            { required: true, message: "请输入赠送原因", trigger: "blur" },
        ],
    },
    totalData: {},
    tableData: []
});
const showLatestBalance = ref('')

const { totalListForm, searchForm, formDataDonate, formDataDonateRule, tableParams, marketList, pickerOptions, pickerOptions2, totalData, tableData } = toRefs(data);

// 使用watch监控表单字段的变化
// watch(() => formDataDonate.value, (newValue, oldValue) => {
//     // 表单数据变化时触发表单校验
//     formDataDonateRef.value.validate((valid) => {
//         if (valid) {
//             console.log('表单校验成功');
//             formIsValid.value = true
//         } else {
//             console.log('表单校验失败');
//         }
//     });
// }, { deep: true });

function handleTimeData(date){ //查询统计
	searchForm.value.startTime = transformTime(date[0])
	searchForm.value.endTime = transformTime(date[1])
    searchForm.value.mobile = ''
    searchForm.value.stat = ''
	searchForm.value.classify = ''
	totalListForm.value = {
		...searchForm.value
	}
	getTotalListFn()
}

function getWalletListFn() {
    tableLoading.value = true;
    getWalletList(tableParams.value)
        .then((res) => {
            tableLoading.value = false;
            if (res.data && res.data.records.length > 0) {
                tableData.value = [...res.data.records];
            }
            paginationObj.total = res.data.total
        })
        .catch((e) => {
            tableLoading.value = false;
        });
}
getWalletListFn() //获取表格数据

function getTotalListFn() {
    const currentDate = new Date();
	const year = currentDate.getFullYear();//获取当前年
	const month = String(currentDate.getMonth() + 1).padStart(2, "0");//获取当前月
	const firstDay = "01";//日
	const beforeDay = `${year}-${month}-${firstDay}`

	const dd = String(currentDate.getDate()).padStart(2, '0');
	const mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // 1月是0!
	const yyyy = currentDate.getFullYear();
	const today = yyyy + '-' + mm + '-' + dd;

	
    getTotalList(totalListForm.value)
        .then((res) => {
            if (res.data) {
                if (dialogVisibleDonate.value) {
                    showLatestBalance.value = res.data.latestBalance
                }else{
                    totalData.value = res.data
                }
            }
        })
        .catch((e) => { });
}
getTotalListFn() //获取汇总数据

//车主档案详情
function handleSelectTab(e) {
	showTabItem.value = e.index == 0 ? true : false
}

function handleTabClick(tab, event) {
    console.log(tab, event, 88);
}

function handleSizeChange(val) {
    paginationObj.listPageSize = val;
    tableParams.value.pageSize = val
    getWalletListFn();
}
function handleCurrentChange(val) {
    paginationObj.pageNo = val;
    paginationObj.page = val;
    tableParams.value.pageNo = val
    getWalletListFn();
}

function handleOpenDialog() {
    dialogVisibleDonate.value = true
}

function handleisShowDetailDrawer(row) {
	sendMobile.value = row.mobile
	sendUserId.value = row.id
	isShowDetailDrawer.value = true
}

// 标准日转化为年月日
function transformTime(date) {
    const d = new Date(date);
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
}

function searchData() { //查询
    if (!searchForm.value.timeRange) {
        Message('请选择交易时间')
        return
    }
    if (searchForm.value.timeRange[0] == '') {
        Message('请选择开始交易时间')
        return
    }
    if (searchForm.value.timeRange[1] == '') {
        Message('请选择结束交易时间')
        return
    }
    searchForm.value.startTime = transformTime(searchForm.value.timeRange[0])
    searchForm.value.endTime = transformTime(searchForm.value.timeRange[1])
    if (searchForm.value.mobile != '') {
        if (!/^0|[1-9]\d*$/.test(searchForm.value.mobile)) {
            Message('请输入手机号')
            return
        }
    }

    tableParams.value = { ...searchForm.value }
    console.log(tableParams.value, 6666);
    tableData.value = []
    getWalletListFn()
}

function resetData() { //重置
    searchFormRef.value.resetFields()
    searchForm.value.startTime = ''
    searchForm.value.endTime = ''
    tableParams.value = { ...searchForm.value }
    console.log(tableParams.value, 8989);
    tableData.value = []
    getWalletListFn()

}

function handleDonateInput() {
    tableParams.value.mobile = formDataDonate.value.mobile
    if (formDataDonate.value.mobile.length == 11 && dialogVisibleDonate.value) {
        totalListForm.value.mobile = formDataDonate.value.mobile
        getTotalListFn()
    }
}

function handleClear() {
    showLatestBalance.value = '--'
}

function handleDialogDonateClose() {
    showLatestBalance.value = '--'
    isValidateView.value = false
    formDataDonate.value.giftReasonSel = 1
    formDataDonateRef.value.clearValidate();
    formDataDonateRef.value.resetFields();
}

function changeNodeType(e) {
    isValidateView.value = e == 1 ? false : true
}

function handleSureDonate() {
    formDataDonateRef.value.validate((valid) => {
        if (valid) {
            formDataDonate.value.giftReason = formDataDonate.value.giftReasonSel == 1 ? '测试' : formDataDonate.value.giftReasonText
            getRewardGift(formDataDonate.value)
                .then((res) => {
                    if (res.message.code == 1015) {
                        Message(res.message.message)
                    } else {
                        Message.success('操作成功')
                        getTotalListFn()
                        getWalletListFn()
                        dialogVisibleDonate.value = false
                    }
                }).catch((e) => { });
        }
    })
}

function handleInput(value){
    if (value.includes('.') && value.split('.')[1] && value.split('.')[1].length > 2) {
        const fourthDigit = value.split('.')[1][2];
        if (fourthDigit && fourthDigit !== '0') {
          value = value.substring(0, value.length - 1);
        }
      }
      const formattedValue = value.replace(/[^\d.]/g, '');
      
      formDataDonate.value.giftAmount = formattedValue
}
</script>

<style lang="scss" scoped>
.journal-body {
    height: 100%;
    margin-top: 56px;

    .balance-body {
        width: 100%;
        height: 100%;
        padding: 0 16px;

        .data-container {
            .middle-data {
                margin: 20px 0 12px;

                .left-content,
                .right-content {
                    padding: 16px;
                    border-radius: 8px;
                    background-color: #fff;

                    .data-datail {
                        margin-top: 12px;
                        padding: 16px;
                        border-radius: 8px;
                        border: 1px solid var(--PC--, #E5E6EB);
                    }

                    .item-data {
                        display: flex;
                        align-items: center;

                        .item-title {
                            margin-bottom: 8px;
                            color: #4E5969;
                            font-size: 14px;
                            font-weight: 400;
                        }

                        .item-val {
                            color: #1D2129;
                            font-family: "D-DIN-PRO";
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                }

                .left-content, .right-content{

                    // ::v-deep .el-input__icon.el-icon-date {
                    // 	display: none;
                    // }
                    .left-title,.right-title {
                        color: var(--PC-, #1D2129);
                        font-family: "PingFang SC";
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 22px;
                    }
                }

                .left-icon {
                    margin-right: 8px;

                    .img-content {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
        }
    }

    .search-content {
        padding: 16px 16px 0 0;
        border-radius: 8px;
        background-color: #fff;
    }

    .table-body {
        margin-top: 12px;
        padding: 16px;
        border-radius: 8px;
        background-color: #fff;
    }

    .table-list {
        margin-top: 20px;
    }

    .donate-title {
        padding: 9px 12px;
        border-radius: 4px;
        border: 1px solid #FFE58F;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        font-weight: 400;
        background: #FFFBE6;

        img {
            margin-right: 3px;
            vertical-align: middle;
            width: 16px;
            height: 16px;
        }
    }

    .donate-form {
        margin-top: 16px;

        .nav-form-item {
            margin-bottom: 22px;
        }

        .list-item {
            display: flex;

            .item-input {
                flex: 1;
            }

            .item-money {
                margin-left: 8px;
                color: var(--PC-, #1D2129);
                font-size: 14px;
                font-weight: 400;
            }
        }

    }
}

/**表格样式通用 */

//弹框样式
::v-deep .el-dialog {
    border-radius: 8px;
}

::v-deep .el-dialog__title {
    color: #1D2129;
}

::v-deep .el-dialog__header {
    border-bottom: 1px solid #E5E6EB;
}

::v-deep .el-upload--picture-card {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #E5E6EB;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 60px;
    height: 60px;
}

::v-deep .el-dialog__footer {
    padding: 10px 20px 10px;
    border-top: 1px solid #E5E6EB;
}

/**表格样式通用 */
::v-deep .el-table td {
    /* 设置字体大小 */
    font-size: 14px;
}

.showDrawer-detail { //详情弹框
	::v-deep .el-drawer__header {
		border-bottom: 1px solid #e5e6eb;
	}

	::v-deep .el-tabs__content {
		background-color: '#fff' !important;
	}

	::v-deep .el-tabs__header {
		position: relative;
		z-index: 0;
		width: 100%;
	}

	.footer-close-btn {
		position: absolute;
		width: 100%;
		bottom: 0;
		right: 0;
		text-align: right;
		border-top: 1px solid #E5E6EB;
		padding: 10px 24px;
		background-color: #fff;
	}
}
</style>