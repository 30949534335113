<template>
  <div class="main">
    <div class="content-box">
      <div
        class="title"
        style="justify-content: space-between;flex-direction: column"
      >
        <div>
          <el-select clearable v-model="type" placeholder="请选择代理商">
            <el-option label="按代理商统计" :value="1"></el-option>
            <el-option label="按电桩统计" :value="2"></el-option>
          </el-select>
          <el-input
            style="width:280px;margin-left:20px;"
            placeholder="搜索代理商姓名/手机号"
            v-model="search"
          >
            <el-button
              slot="suffix"
              @click="searchData"
              type="primary"
              icon="el-icon-search"
              style="border-radius:0 4px 4px 0;margin-right:-5px"
              >搜索</el-button
            >
          </el-input>

          <el-button
            style="margin-left:20px;"
            type="primary"
            @click="getSumFinace"
            >查看总销售额</el-button
          >
          <el-button
            style="margin-left:20px;"
            type="primary"
            @click="exportfinance"
            class="import-btn"
          >
            <img src="@/../public/images/import.png" />导出
          </el-button>
        </div>
      </div>
    </div>
    <div class="content-box">
      <el-table v-loading="loading" border :data="tableData" style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="userNm"
          label="代理商姓名"
          align="center"
          min-width="120"
        ></el-table-column>

        <el-table-column
          v-if="type != 1"
          prop="pileId"
          label="电桩编号"
          align="center"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="手机号"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="daySales"
          label="日销售额 "
          align="center"
          min-width="120"
        ></el-table-column>

        <el-table-column
          prop="monSales"
          label="月销售额 "
          align="center"
          min-width="120"
        ></el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog title="总销售额" :visible.sync="dialogVisible" width="30%">
      <div class="order">
        <div class="order-1">
          <div class="text">日销售额:</div>
          ￥{{ data.daySales }}
        </div>
        <div class="order-1">
          <div class="text">月销售额:</div>
          ￥{{ data.monSales }}
        </div>
        <div class="order-1">
          <div class="text">年销售额:</div>
          ￥{{ data.ySales }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getFinanceList, getSumFinace } from "@/api/finance";
import { getDataScopeList } from "@/api/user";

export default {
  data() {
    return {
      dialogVisible: false,
      url: process.env.VUE_APP_BASE_API,
      type: 1,
      search: "",
      tableData: [],
      page: 1,
      data: {},
      pageSize: 10,
      loading: true,
      total: 0,
      options: [],
      formRule: {
        mobile: [{ required: true, message: "请输入必填项", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getDataList();
    // this.getDataScopeList()
  },
  methods: {
    exportfinance() {
      window.location.href =
        this.url +
        "/finance/export?keyWord=" +
        this.search +
        "&type=" +
        this.type;
      // exportfinance(param).then(res => {})
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let param = {
        keyWord: this.search,
        pageNo: this.page,
        pageSize: this.pageSize,
        type: this.type,
      };
      getFinanceList(param).then((res) => {
        if (res.data) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    getSumFinace() {
      this.dialogVisible = true;
      this.data = Object.assign({}, {});
      getSumFinace().then((res) => {
        this.data = Object.assign({}, res.data);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: row;
  .import-btn {
    img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
}

.data {
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 20px;
}

.order {
  display: flex;
  flex-direction: column;
  color: #333;

  .text {
    color: var(--current-color);
    width: 100px;
  }

  .order-1 {
    padding: 8px 10px;
    display: flex;
  }
}
</style>
