<template>
  <div class="main">
    <div class="content-box">
      <el-select clearable v-model="operatorId" placeholder="请选择代理商">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.operatorNm"
          :value="item.operatorId"
        ></el-option>
      </el-select>
      <el-input
        style="width:280px;margin-left:20px;"
        placeholder="搜索用户名/手机号"
        v-model="userOrNum"
      >
        <el-button
          slot="suffix"
          @click="searchData"
          type="primary"
          icon="el-icon-search"
          style="border-radius:0 4px 4px 0;margin-right:-5px"
          >搜索</el-button
        >
      </el-input>
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="userName"
          label="用户昵称"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="realName"
          label="代理商"
          align="center"
          min-width="80"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="问题类型"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="state"
          align="center"
          label="处理状态 "
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="rmk"
          label="问题描述 "
          align="center"
          min-width="80"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="用户手机号 "
          align="center"
          min-width="110"
        ></el-table-column>
        <!--        <el-table-column prop="imgUrl" label="现场图片" align="center" min-width="80">-->
        <!--          <template slot-scope="scope">-->
        <!--            <div class="img">-->
        <!--              <img :src="scope.row.imgUrl" />-->
        <!--            </div>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
          prop="handler"
          label="处理人"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="handleTime"
          label="处理时间"
          align="center"
          min-width="130"
        ></el-table-column>
        <el-table-column
          prop="deviceNo"
          label="设备编号 "
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          min-width="130"
        ></el-table-column>
        <el-table-column
          prop="comment"
          label="批注 "
          align="center"
          min-width="80"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button
                type="primary"
                v-if="scope.row.state == '待处理'"
                @click="detailHandler(scope.row)"
                >处理</el-button
              >
            </div>
            <!-- <i @click="deleteHandler(scope.row)" class="el-icon-delete"></i> -->
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @pagination="handleCurrentChange"
        :background="false"
        :page.sync="page"
        :limit.sync="pageSize"
        :total="total"
      ></pagination>
    </div>
    <el-dialog title="查看详情" :visible.sync="dialogVisible" width="30%">
      <div class="order">
        <div class="order-1">
          <div class="text">用户昵称</div>
          {{ data.userName }}
        </div>
        <div class="order-1">
          <div class="text">问题描述</div>
          {{ data.rmk }}
        </div>
        <div class="order-1">
          <div class="text">手机号</div>
          {{ data.mobile }}
        </div>
        <div class="order-1">
          <div class="text">问题类型</div>
          {{ data.type ? data.type : "其他问题" }}
        </div>
        <div class="order-1">
          <div class="text">创建时间</div>
          {{ data.createTime }}
        </div>
        <div class="order-1">
          <div class="text">批注</div>
          <el-input v-model="comment" placeholder="请输入批注信息"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doFeedBack('2')">忽略</el-button>
        <el-button type="primary" @click="doFeedBack('1')">处理</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getDataScopeList } from "@/api/user";
import { getFeedBackList, getFeedBack, doFeedBack } from "@/api/feedback";
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      operatorId: null,
      dialogVisible: false,
      id: "",
      comment: "",
      options: [],
      tableData: [],
      cardNo: this.$route.query.cardNo,
      page: 1,
      pageSize: 10,
      loading: true,
      total: 0,
      data: {},
      userOrNum: "",
    };
  },
  created() {
    this.getDataList();
    this.getDataScopeList();
  },
  methods: {
    getDataScopeList() {
      getDataScopeList().then((res) => {
        if (res.data && res.data.length > 0) {
          this.options = [...res.data];
        }
      });
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let param = {
        userId: this.operatorId,
        keyWord: this.userOrNum,
        cardNo: this.cardNo,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getFeedBackList(param).then((res) => {
        if (res.data && res.data.records.length >= 0) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    handleCurrentChange({ page }) {
      this.page = page;
      this.getDataList();
    },
    detailHandler(row) {
      let param = {
        id: row.id,
      };
      getFeedBack(param).then((res) => {
        if (res.data.state == "处理完成") {
          this.$message({
            message: "你选择的问题已处理完成,请选择其他问题！",
            type: "info",
          });
        } else {
          this.id = res.data.id;
          this.comment = "";
          this.dialogVisible = true;
          this.data = Object.assign({}, res.data);
        }
      });
    },
    doFeedBack(state) {
      let param = {
        id: this.id,
        comment: this.comment,
        state: state,
      };
      doFeedBack(param).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}

.title {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.order {
  display: flex;
  flex-direction: column;
  color: #333;

  .text {
    color: var(--current-color);
    width: 100px;
  }

  .order-1 {
    padding: 8px 10px;
    display: flex;
  }
}

.img {
  width: 200px;
  height: 50px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
